<template>
  <div class="signup-user" v-if="!isProcessing">
    <p class="signup-user__text">
      あなたの情報を教えてください。<br>なお、
      <span class="signup-user__text__red">プロフィール写真と名前はFacebookグループの承認の際に本人確認として利用するので、Facebookに登録しているものと同じものをご登録いただけると助かります。</span>
    </p>
    <signup-form class="signup-user__profile" @set-postparams="setPostParams" @set-blob="setBlob" />
    <v-btn class="signup-user__btn" depressed :disabled="!isValid" @click="registrateUser()">あなたの情報を登録する</v-btn>
  </div>
</template>
<script>
import signupForm from '@/components/common/form/user.vue'

export default {
  name: 'signup-user',
  components: { signupForm },
  data () {
    return {
      postParams: {
        // プロフィール画像
        icon: '',
        // 名前
        name: '',
        // 自己紹介文
        profile: ''
      },
      // Blobファイル
      blob: null,
      // 画像の格納先のパス名
      path: ''
    }
  },
  mounted () {
    // 例外情報の取得
    if (!this.isGotExemption) this.$store.dispatch('exemptions/getExemption', this.uid)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Boolean} 例外情報取得済みかどうか
     */
    isGotExemption () {
      return this.$store.getters['exemptions/isGot']
    },
    /**
     * @return {Object} 例外情報
     */
    exemption () {
      return this.$store.getters['exemptions/exemption']
    },
    /**
     * @return {Boolean} 名前と自己紹介文が入力されているかどうか
     */
    isValid () {
      const keys = ['icon', 'name', 'profile']
      const isFilled = keys.filter(key => this.postParams[key].trim().length > 0).length === keys.length
      const isCorrectProfile = this.postParams.name.length <= 20 && this.postParams.profile.length <= 160
      return isFilled && isCorrectProfile
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters['isProcessing']
    }
  },
  methods: {
    /**
     * ユーザー情報の登録
     */
    async registrateUser () {
      this.$store.commit('setSubmitting', true)

      // 画像のアップロード
      const url = await this.$store.dispatch('cloudstorage/uploadImage', { blob: this.blob, path: this.path })
      this.postParams.icon = url

      // ユーザー情報に作成時間と変更時間等の情報追加
      this.postParams.createdAt = new Date()
      this.postParams.updatedAt = new Date()
      this.postParams.isFacebookMember = false
      this.postParams.isDeleted = false
      this.postParams.authority = 'member'

      // ユーザー情報の更新
      await this.$store.dispatch('users/updateUser', { uid: this.uid, params: this.postParams })

      // 例外情報の有無で分岐
      if (this.exemption) {
      // 例外情報があるなら登録完了メールを送信してSignupDone画面へ遷移する
        this.$store.dispatch('functions/sendEventEmail', 'registratedExemptionUser')
        this.$router.push({ name: 'signup_done' })
      } else {
      // 例外情報がないならSignupCard画面へ遷移する
        this.$router.push({ name: 'signup_card' })
      }
    },
    /**
     * ポストパラメータを取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {String} value 設定するポストパラメータのバリュー値
     */
    setPostParams (key, value) {
      this.postParams[key] = value
    },
    /**
     * Blobファイルの代入
     * @param {Blob} blob 格納するBlobファイル
     * @param {String} path 格納する画像の格納先のパス名
     */
    setBlob (blob, path) {
      this.blob = blob
      this.path = path
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.signup-user {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width 0;
  margin: $header_height auto 0;
  &__text {
    margin: 0;
    font-size: 1.2rem;
    &__red {
      color: $red_color;
    }
  }
  &__profile {
    margin-top: 20px;
  }
  &__btn {
    display: block;
    margin: 30px auto 0;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 124px;
      height: 35px;
      padding: 0 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
